import request from '@/utils/request'

const VERSION = process.env.VUE_APP_VERSION

export function articleList(data) {
  return request({
    url: VERSION + '/article/list',
    method: 'get',
    params: data
  })
}

export function articleInfo(data) {
  return request({
    url: VERSION + '/article/info',
    method: 'get',
    params: data
  })
}

export function notice(data) {
  return request({
    url: VERSION + '/article/list',
    method: 'get',
    params: data
  })
}

//首页轮播图
export function ad(data) {
  return request({
    url: VERSION + '/content/ad',
    method: 'get',
    params: data
  })
}
