<template>
  <div class="home">
    <div class="banner"><img src="../assets/images/banner1.jpg" alt="" /></div>
    <div class="current-position">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span class="icon-home"></span>首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ this.navbar_title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="news-detail">
      <h3>{{ title }}</h3>
      <div class="info" v-html="content"></div>
    </div>
  </div>
</template>

<script>
import { articleInfo } from '../api/article'

export default {
  name: 'Home',
  data() {
    return {
      navbar_title: '',
      title: '',
      content:
        ' 超车由几个驾驶动作复合而成，不但需要并线技巧，还需要油门和挡位的合理匹配。很多人并不能完全掌握超车技巧，在超车时存在误区和技术缺陷。1:超车的基本动作:超车的动作归结起来是：加速并线进入旁边车道，加速直行超过需要超的车辆直至安全距离后并线回到原车道。通过超车动作，实现快速通行的目的。2:超车的时机当你发现你前面的车由于自身的原因已经严重影响到整个车道的车流速度，同时旁边有可以安全借道的车道，这时你可以考虑超车。一般情况下，被超车辆具有以下特征：新手不敢提速的车、缓慢行驶的大客车或货车、路线不熟驾驶犹豫的车辆、边打电话边驾驶的车辆，当然还有一些不好判断原因却一直保持慢速行驶的车辆。'
    }
  },
  computed: {},
  created() {
    this.navbar_title = this.$route.query.navbar_title
    this.id = this.$route.query.id
    this.getContent()
  },
  methods: {
    getContent() {
      articleInfo({ id: this.id }).then(res => {
        if (res.code === 0) {
          this.title = res.result.title
          this.content = this.HTMLEncode(res.result.pc_content)
        }
      })
    },
    HTMLEncode(text) {
      let temp = document.createElement('div')
      temp.innerHTML = text
      let output = temp.innerText || temp.textContent
      temp = null
      return output
    }
  },
  components: {}
}
</script>
